import img1_1 from '../../../images/our-works/Рудики/w1.jpg';
import img1_2 from '../../../images/our-works/Рудики/w2.jpg';
import img1_3 from '../../../images/our-works/Рудики/w3.jpg';
import img1_4 from '../../../images/our-works/Рудики/photo.png';

import img2_1 from '../../../images/our-works/Вишеньки/w4.jpg';
import img2_2 from '../../../images/our-works/Вишеньки/w5.jpg';
import img2_3 from '../../../images/our-works/Вишеньки/w6.jpg';
import img2_4 from '../../../images/our-works/Вишеньки/photo.png';

import img3_1 from '../../../images/our-works/Петропавлівське/w7.jpg';
import img3_2 from '../../../images/our-works/Петропавлівське/w8.jpg';
import img3_3 from '../../../images/our-works/Петропавлівське/w9.jpg';

import img4_1 from '../../../images/our-works/Конча-Заспа/-1.png';
import img4_2 from '../../../images/our-works/Конча-Заспа/w10.jpg';
import img4_3 from '../../../images/our-works/Конча-Заспа/w11.jpg';
import img4_4 from '../../../images/our-works/Конча-Заспа/w21.jpg';
import img4_5 from '../../../images/our-works/Конча-Заспа/w22.jpg';
import img4_6 from '../../../images/our-works/Конча-Заспа/w23.jpg';

import img5_1 from '../../../images/our-works/Пшенична 8/w12.jpg';
import img5_2 from '../../../images/our-works/Пшенична 8/w13.jpg';
import img5_3 from '../../../images/our-works/Пшенична 8/w14.jpg';

import img6_1 from '../../../images/our-works/Бобриця/w15.jpg';
import img6_2 from '../../../images/our-works/Бобриця/w16.jpg';
import img6_3 from '../../../images/our-works/Бобриця/photo.png';

import img7_1 from '../../../images/our-works/Гореничі/w17.jpg';
import img7_2 from '../../../images/our-works/Гореничі/w17.jpg';
import img7_3 from '../../../images/our-works/Гореничі/w17.jpg';
import img7_4 from '../../../images/our-works/Гореничі/w20.jpg';
import img7_5 from '../../../images/our-works/Гореничі/photo.png';

import img8_1 from '../../../images/our-works/Конча-Заспа, Дамба 3-й км/w24.jpg';
import img8_2 from '../../../images/our-works/Конча-Заспа, Дамба 3-й км/w25.jpg';
import img8_3 from '../../../images/our-works/Конча-Заспа, Дамба 3-й км/-1.png';

import img9_1 from '../../../images/our-works/Хотів/w26.jpg';
import img9_2 from '../../../images/our-works/Хотів/w27.jpg';
import img9_3 from '../../../images/our-works/Хотів/w28.jpg';

import img10_1 from '../../../images/our-works/Мотижин Свердловина/__1.png';
import img10_2 from '../../../images/our-works/Мотижин Свердловина/__2.jpg';
import img10_3 from '../../../images/our-works/Мотижин Свердловина/exel.png';

import img11_1 from '../../../images/our-works/Гатне 255м/__1.jpg';
import img11_2 from '../../../images/our-works/Гатне 255м/__4.jpg';
import img11_3 from '../../../images/our-works/Гатне 255м/exel.png';

export const firstGroup = [img1_1, img1_2, img1_3, img1_4];
export const secondGroup = [img2_1, img2_2, img2_3, img2_4];
export const thirdGroup = [img3_1, img3_2, img3_3];
export const fourthGroup = [img4_1, img4_2, img4_3, img4_4, img4_5, img4_6];
export const fifthGroup = [img5_1, img5_2, img5_3];
export const sixthGroup = [img6_1, img6_2, img6_3];
export const seventhGroup = [img7_1, img7_2, img7_3, img7_4, img7_5];
export const eighthGroup = [img8_1, img8_2, img8_3];
export const ninthGroup = [img9_1, img9_2, img9_3];
export const tenthGroup = [img10_1, img10_2, img10_3];
export const eleventhGroup = [img11_1, img11_2, img11_3];
