import React from 'react';

export const ConcreteItem = ({ src, title, subTop, subBottom, price }) => {
  return (
    <div className="concreteRings-item">
      <div className="concreteRings-inner">
        <div className="concreteRings-img">
          <img src={src} alt={title} />
        </div>
        <div className="concreteRings-title">{title}</div>
        <div className="concreteRings-context">
          {subTop && <p>{subTop}</p>}
          <p>{subBottom}</p>
        </div>
        <p className="concreteRings-price">
          {price} <span>грн.</span>
        </p>
      </div>
    </div>
  );
};
