import React from 'react';

import img1 from '../../../images/img_01.png';
import img2 from '../../../images/img_02.jpg';

export const MainBannerTop = () => {
  return (
    <div className="main-banner__top">
      <img src={img1} alt="5 years warranty" />
      <img src={img2} alt="water analysis" />
    </div>
  );
};
