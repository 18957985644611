import React from 'react';
import { Typography } from '@mui/material';

export const BannerActionContent = () => {
  return (
    <div className="main-banner__text">
      <Typography variant="h2" gutterBottom className="action__title">
        Акція - Подарунок
      </Typography>
      <Typography variant="h3" gutterBottom className="action__subtitle">
        При замовленні свердловини"під ключ" комплект автоматики- <span>В ПОДАРУНОК</span>
      </Typography>
    </div>
  );
};
