import React from 'react';
import { Link } from '@mui/material';

import './style.scss';

const phonesList = [
  {
    number: '066-848-04-33',
    href: 'tel:+380668480433',
  },
];

export const Phones = ({ handleCloseMenu }) => {
  return (
    <div className="phones">
      {phonesList.map((phone) => (
        <Link
          color="primary"
          variant="button"
          underline="hover"
          href={phone.href}
          onClick={() => handleCloseMenu?.()}
          key={phone.number}
        >
          {phone.number}
        </Link>
      ))}
    </div>
  );
};
