import React from 'react';

export const VariantItem = ({
  title,
  img,
  price,
  text,
  active,
  onClick,
  id,
}) => {
  return (
    <div className={active ? 'variants-item decor' : 'variants-item'}>
      <div className="variants-inner">
        <div className="variants-img">
          <img src={img} alt="img" />
        </div>
        <div className="variants-content">
          <div className="variants-title">{title}</div>
          <p className="variants-text">{text}</p>
          <p className="variants-price">
            від <span>{price}</span>
            грн.
          </p>
          <button onClick={() => onClick(id)}>Замовити</button>
        </div>
      </div>
    </div>
  );
};
