import React from 'react';

import { IconButton, SwipeableDrawer, Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { MainNavigation } from './components/MainNavigation/MainNavigation';
import { Phones } from './components/Phones/Phones';
import { SocialLinks } from './components/SocialLinks/SocialLinks';
import { CallBackPhone } from '../CallBackPhone/CallBackPhone';

export const MobileMenu = ({ open, handleOpenMenu, handleCloseMenu }) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={handleOpenMenu}
      onClose={handleCloseMenu}
      minFlingVelocity={1000}
      className="mobile-menu__container"
    >
      <div
        onClick={handleCloseMenu}
        onKeyPress={handleCloseMenu}
        role="button"
        tabIndex={0}
      >
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Divider />
      <div className="mobile-menu">
        <div className="mobile-menu_navigation">
          <MainNavigation handleCloseMenu={handleCloseMenu} />
        </div>
        <Phones handleCloseMenu={handleCloseMenu} />
        <SocialLinks />
        <CallBackPhone handleCloseMenu={handleCloseMenu} />
      </div>
    </SwipeableDrawer>
  );
};
