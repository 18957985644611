import React from 'react';
import './style.scss';

export const Map = () => {
  return (
    <div className="section-map" id="maps">
      <iframe
        src="https://www.google.com/maps/d/embed?mid=1bTi-ElaTqojcNm_aroSkrZ4ilnb50Jv4"
        width="640"
        height="480"
        title="map"
      ></iframe>
    </div>
  );
};
