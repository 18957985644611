import React from 'react';
import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormComponent } from '../MainBanner/components/FormComponent';

import './style.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  boxShadow: '0 0 20px 0 rgba(0 0 0, 0.4)',
  p: 4,
};

export const ModalComponent = ({ isShowing, onToggle, children }) => {
  return (
    <Modal
      open={isShowing}
      onClose={onToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="modal">
        <div className="modal-inner-block">
          {children}
          <Typography id="modal-modal-title" variant="h5" component="h2" className="semiBold">
            Залиште свій номер телефону і ми передзвонимо вам!
          </Typography>
          <div className="modal-form">
            <FormComponent text="Зателефонувати" />
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} className="textCenter">
            Або зателефонуйте самі:
          </Typography>
          <p className="modal-link">
            <a href="tel:+380668480433">066-848-04-33</a>
          </p>
        </div>
      </Box>
    </Modal>
  );
};
