import React from 'react';
import { Container, Typography } from '@mui/material';

import img1 from '../../images/img_02.png';
import img2 from '../../images/img_07.jpg';

import './style.scss';
import { VariantItem } from './VariantItem';

const data = [
  {
    id: 1,
    img: img1,
    title: 'Облаштування септиків з бетонних кілець',
    price: '15 000',
  },
  {
    id: 2,
    img: img2,
    title: 'Облаштування локальних очисних споруд (ЛОС)',
    price: '80 000',
  },
];

export const Variants2 = () => {
  return (
    <div className="block-padding variants" id="variants2">
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom component="div" className=" semiBold textCenter mainTitle">
          Септики
        </Typography>
        <div className="variants-row">
          {data.map((item, i) => (
            <VariantItem key={item.id} title={item.title} img={item.img} price={item.price} />
          ))}
        </div>
      </Container>
    </div>
  );
};
