import React, { useState } from 'react';

import img1 from '../../../images/pic_39.png';

export const ItemRight = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  return (
    <div className="constructions-item">
      <div
        className={
          toggle
            ? 'constructions-item__inner open'
            : 'constructions-item__inner'
        }
      >
        <div className="constructions-img">
          <img src={img1} alt="img" />
        </div>
        <div className="constructions-content">
          <h4>Конструкція свердловини в одну колону</h4>
          <h4> (верховодка, «полтава». «бучак»)</h4>
        </div>
        <div className="constructions-description">
          <div className="constructions-description__inner">
            <h5>Верхні грунтові води "верховодка"</h5>
            <p>
              Глибина залягання - від 1,5 до 15м, запаси їх залежать від
              кількості вологи, яка випадає у вигляді дощів та снігів (на
              сьогоднішній день відслідковується тенденція до падіння об"ємів у
              цьому прошарку). Плюсами буріння на "верховодку" є ціна і
              дешевизна в облаштуванні, але значним мінусом є якість води, яка
              забруднюється з тими ж дощами, які наповнюють її всіма нечистотами
              з поверхні землі та каналізації.
            </p>
            <h5>Полтавський горизонт</h5>
            <p>
              залягає на глибині 20м-60м, розміщений майже на всій території
              правобережжя Київської області. Даний горизонт може давати дебіт
              до 3м.куб./год., по якості має перевищення по залізу, в деяких
              регіонах - по нітратам. Полтавський горизонт є одним з
              найпопулярніших в Київській області. В деяких населених пунктах
              хімічний аналіз кращий за бучацький горизонт.
            </p>
          </div>
        </div>
        <div className="constructions-btn">
          <button className="constructions-toggle" onClick={handleToggle}>
            Open
          </button>
        </div>
      </div>
    </div>
  );
};
