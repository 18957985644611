import React from 'react';
import { Container, Typography } from '@mui/material';

import './style.scss';
import { ItemLeft } from './components/ItemLeft';
import { ItemRight } from './components/ItemRight';

export const Constructions = () => {
  return (
    <div className="block-padding">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className=" semiBold textCenter mainTitle"
        >
          Що потрібно знати при визначенні з водоносним горизонтом
        </Typography>

        <div className="constructions-container">
          <ItemLeft />
          <ItemRight />
        </div>
      </Container>
    </div>
  );
};
