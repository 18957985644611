import { Container, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';

import img1 from '../../images/our-partners/p-1.jpg';
import img2 from '../../images/our-partners/p-2.jpg';
import img3 from '../../images/our-partners/p-3.jpg';
import img4 from '../../images/our-partners/p-4.jpg';
import img5 from '../../images/our-partners/p-5.jpg';
import img6 from '../../images/our-partners/p-6.jpg';
import img7 from '../../images/our-partners/p-7.jpg';
import img8 from '../../images/our-partners/p-8.jpg';
import img9 from '../../images/our-partners/p-9.jpg';
import { Item } from './Item';

const data = [
  {
    id: 0,
    src: img1,
    alt: 'Santic',
  },
  {
    id: 1,
    src: img2,
    alt: 'Grundfos',
  },
  {
    id: 2,
    src: img3,
    alt: 'Therm',
  },
  {
    id: 3,
    src: img4,
    alt: 'Aouasystem',
  },
  {
    id: 4,
    src: img5,
    alt: 'Antars-v',
  },
  {
    id: 5,
    src: img6,
    alt: 'Valrom',
  },
  {
    id: 6,
    src: img7,
    alt: 'Zilmet',
  },
  {
    id: 7,
    src: img8,
    alt: 'Pedrollo',
  },
  {
    id: 8,
    src: img9,
    alt: 'Grundfos',
  },
];

export const CarouselComponent = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="block-padding">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className=" semiBold textCenter mainTitle"
        >
          Що потрібно знати при визначенні з водоносним горизонтом
        </Typography>
        <Carousel responsive={responsive}>
          {data.map((item) => (
            <Item key={item.id} src={item.src} alt={item.alt} />
          ))}
        </Carousel>
      </Container>
    </div>
  );
};
