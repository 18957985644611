import { Container, Typography } from '@mui/material';
import React from 'react';

import './style.scss';

import img1 from '../../images/concrete-rings/1.jpg';
import img2 from '../../images/concrete-rings/2.jpg';
import { ConcreteItem } from './ConcreteItem';

const data = [
  {
    id: 0,
    src: img1,
    title: 'Кільце колодязне КС-8.7',
    subTop: 'Висота-70см',
    subBottom: 'Внутрішній діаметр-80см',
    price: '600',
  },
  {
    id: 1,
    src: img1,
    title: 'Кільце колодязне КС-10.9',
    subTop: 'Висота-90см',
    subBottom: 'Внутрішній діаметр-100см',
    price: '900',
  },
  {
    id: 2,
    src: img1,
    title: 'Кільце колодязне КС-15.9',
    subTop: 'Висота-90см',
    subBottom: 'Внутрішній діаметр-150см',
    price: '1250',
  },
  {
    id: 3,
    src: img1,
    title: 'Кільце колодязне КС-20.9',
    subTop: 'Висота-90см',
    subBottom: 'Внутрішній діаметр-200см',
    price: '1750',
  },
  {
    id: 4,
    src: img1,
    title: 'Кільце-стакан (з дном) КСД-15.9',
    subTop: 'Висота-90см',
    subBottom: 'Внутрішній діаметр-150см',
    price: '2550',
  },
  {
    id: 5,
    src: img1,
    title: 'Кільце-стакан (з дном) КСД-20.9',
    subTop: 'Висота-90см',
    subBottom: 'Внутрішній діаметр-150см',
    price: '3500',
  },
  {
    id: 6,
    src: img2,
    title: 'Плита перекриття (кришка) ПП-1.08 ',
    subBottom: 'Товщина-10см',
    price: '600',
  },
  {
    id: 7,
    src: img2,
    title: 'Плита перекриття (кришка) ПП-1.10',
    subBottom: 'Товщина-15см',
    price: '900',
  },
  {
    id: 8,
    src: img2,
    title: 'Плита перекриття (кришка) ПП-1.15',
    subBottom: 'Товщина-15см',
    price: '1250',
  },
  {
    id: 9,
    src: img2,
    title: 'ППлита перекриття (кришка) ПП-1.20',
    subBottom: 'Товщина-15см',
    price: '1750',
  },
];

export const ConcreteRings = () => {
  return (
    <div className="block-padding concreteRings" id="concreteRings">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className=" semiBold textCenter mainTitle"
        >
          Бетонні кільця
        </Typography>
        <div className="concreteRings-row">
          {data.map((item) => (
            <ConcreteItem
              key={item.id}
              src={item.src}
              title={item.title}
              subTop={item.subTop}
              subBottom={item.subBottom}
              price={item.price}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};
