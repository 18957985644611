import { Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useModal } from '../../hooks/useModal';

import img1 from '../../images/pic_36.png';
import img2 from '../../images/pic_37.png';
import { InnerContentOfModal } from '../InnerContentOfModal/InnerContentOfModal';
import { ModalComponent } from '../Modal/Modal';
import { PriceItem } from './PriceItem';

import './style.scss';

const data = [
  {
    id: 1,
    img: img1,
    title: ' Труба ПЕ ⌀50мм',
    price: '350',
  },
  {
    id: 2,
    img: img1,
    title: ' Пластикова труба нПВХ 125мм',
    price: '550',
  },
  {
    id: 3,
    img: img1,
    title: ' Буріння в дві колони',
    price: '1600',
  },
  {
    id: 4,
    img: img2,
    title: ' Буріння під геотермальні зонди',
    price: '150',
  },
];

export const Price = () => {
  const { isShowing, toggle } = useModal();
  const [isClicked, setIsClicked] = useState(null);

  const handleSetClick = (value) => {
    toggle();
    setIsClicked(data.find(({ id }) => value === id));
  };

  return (
    <div className="block-padding price">
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom component="div" className=" semiBold textCenter mainTitle">
          1 метра буріння
        </Typography>
        <div className="container price-container">
          {data.map((item, i) => (
            <PriceItem
              key={item.id}
              active={i === 1}
              title={item.title}
              src={item.img}
              price={item.price}
              onClick={handleSetClick}
              id={item.id}
            />
          ))}
        </div>

        <ModalComponent isShowing={isShowing} onToggle={toggle}>
          {isClicked && <InnerContentOfModal info={isClicked} />}
        </ModalComponent>
      </Container>
    </div>
  );
};
