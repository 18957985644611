import React from 'react';

import { Link } from '@mui/material';

import './style.scss';

import viber from '../../../../images/social/viber_icon.svg';
import telegram from '../../../../images/social/telegram_icon.svg';
import whatsapp from '../../../../images/social/whatsapp_icon.svg';
import skype from '../../../../images/social/skype_icon.svg';

const socialLink = [
  { name: 'viber', href: 'viber://chat?number=+380668480433', icon: viber },
  { name: 'telegram', href: 'https://telegram.me/Aquamen13', icon: telegram },
  { name: 'whatsapp', href: 'https://wa.me/380668480433', icon: whatsapp },
  { name: 'skype', href: 'skype:live:61cc3f2412373720?chat', icon: skype },
];

export const SocialLinks = () => {
  return (
    <div className="socials">
      {socialLink.map((link) => (
        <Link
          key={link.name}
          variant="button"
          underline="hover"
          href={link.href}
          target="_blank"
        >
          <img src={link.icon} alt={link.name} />
        </Link>
      ))}
    </div>
  );
};
