import React from 'react';
import { Container, Typography } from '@mui/material';
import { ListCards } from './components/ListCards';

import {
  eighthGroup,
  eleventhGroup,
  fifthGroup,
  firstGroup,
  fourthGroup,
  ninthGroup,
  secondGroup,
  seventhGroup,
  sixthGroup,
  tenthGroup,
  thirdGroup,
} from './utils';

import './style.scss';

export const OurWorks = () => {
  return (
    <div className="block-padding" id="ourWorks">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className="semiBold textCenter mainTitle"
        >
          Наші роботи
        </Typography>
        <ListCards
          title='с. Рудики, "сеноман" 53м'
          imagesList={firstGroup}
          countColumns={4}
        />
        <ListCards
          title='с. Вишеньки, "бучак", 60м.'
          imagesList={secondGroup}
          countColumns={4}
        />
        <ListCards
          title='с. Петропавлівське, "Вишневий хуторок", 45м "четвертичний горизонт"'
          imagesList={thirdGroup}
          countColumns={3}
        />
        <ListCards
          title='с. Конча-Заспа, Дамба 5-й км, 40м, "полтавський горизон"'
          imagesList={fourthGroup}
          countColumns={3}
        />
        <ListCards
          title='Київ, вул. Пшенична 8, 40м, "полтавський горизонт"'
          imagesList={fifthGroup}
          countColumns={3}
        />
        <ListCards
          title='с. Бобриця, 55м, "канівський горизонт"'
          imagesList={sixthGroup}
          countColumns={3}
        />
        <ListCards
          title='с. Гореничі, 62м, "бучацький горизонт"'
          imagesList={seventhGroup}
          countColumns={3}
        />
        <ListCards
          title='с. Конча-Заспа, Дамба 3-й км, 53м "сеноманський горизонт" з конструкцією "159мм*112мм з виводом на верх"'
          imagesList={eighthGroup}
          countColumns={3}
        />
        <ListCards
          title='с. Хотів, 60м, "бучацький горизонт"'
          imagesList={ninthGroup}
          countColumns={3}
        />
        <ListCards
          title="Мотижин Свердловина 40м та септик 6,5 м.куб."
          imagesList={tenthGroup}
          countColumns={3}
        />
        <ListCards
          title="Геотермальне буріння. Гатне 255м. 5 свердловин по 51м."
          imagesList={eleventhGroup}
          countColumns={3}
        />
      </Container>
    </div>
  );
};
