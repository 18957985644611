import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

import { Button, InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';

const showToast = (type) => {
  if (type === 'success') {
    toast.success('Дані були успішно відправлені. Дякуємо за заявку', {
      autoClose: 2000,
      position: 'top-right',
      className: 'success',
    });
  }
  if (type === 'error') {
    toast.error('Вибачте, виникла помилка. Спробуйте відправити ще раз ', {
      autoClose: 2000,
      position: 'top-right',
    });
  }
};

export const FormComponent = ({ text }) => {
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const inputCard = useRef(null);
  const [show, setShow] = useState(false);
  const form = useRef();
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
  });

  const validate = (name, value) => {
    switch (name) {
      case 'name':
        if (!value || value.trim() === '') {
          return "Це поле обов'язкове";
        } else {
          return '';
        }
      case 'phone':
        if (!value || value.trim() === '') {
          return "Це поле обов'язкове";
        } else if (value.trim().length < 10) {
          return 'Введіть валідний номер';
        } else {
          return '';
        }
      default:
        return '';
    }
  };

  const handleChange = () => {
    if (inputCard && inputCard.current && inputCard.current.value) {
      const cardValue = inputCard.current.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
      inputCard.current.value = !cardValue[2]
        ? cardValue[1]
        : `${cardValue[1]}-${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ''}`}${`${
            cardValue[4] ? `-${cardValue[4]}` : ''
          }`}`;
      const numbers = inputCard.current.value.replace(/(\D)/g, '');
      setValue(numbers);

      setErrors((prevState) => ({
        ...prevState,
        phone: '',
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validatedName = validate('name', name);
    const validatedPhone = validate('phone', value);

    setErrors({
      name: validatedName,
      phone: validatedPhone,
    });

    if (!validatedName && !validatedPhone) {
      emailjs.sendForm('service_q6rqwcd', 'template_si3dgri', form.current, 'iHf5Bk0ksWTv1s8Ns').then(
        (result) => {
          form.current.reset();
          setName('');
          showToast('success');
        },
        (error) => {
          showToast('error');
        }
      );
    }
  };

  const handleNameChange = (e) => {
    setErrors((prevState) => ({
      ...prevState,
      name: '',
    }));
    setName(e.target.value);
  };

  return (
    <>
      <Box component="form" ref={form} method="POST" id="form1" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          value={name}
          label="Ім'я"
          id="outlined-error-helper-text"
          onChange={handleNameChange}
          name="name"
          error={errors.name}
          helperText={<div style={{ color: '#d32f2f' }}>{errors.name}</div>}
        />
        <TextField
          inputRef={(ref) => (inputCard.current = ref)}
          label="Телефон"
          id="outlined-error-helper-text"
          name="phone"
          onChange={handleChange}
          error={errors.phone}
          helperText={<div style={{ color: '#d32f2f' }}>{errors.phone}</div>}
          onFocus={() => {
            setShow(true);
          }}
          onBlur={() => {
            if (value) {
              setShow(true);
            } else {
              setShow(false);
            }
          }}
          InputProps={{
            startAdornment: show && <InputAdornment position="start">+38</InputAdornment>,
          }}
        />

        <Button type="submit" variant="contained" color="error" className="main-banner__form-btn">
          {text ? text : 'Розрахувати вартість'}
        </Button>
      </Box>
    </>
  );
};
