import React from 'react';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const list = [
  {
    id: 1,
    text: 'без передоплати',
  },
  {
    id: 2,
    text: 'з якісною обсадною трубою НПВХ 125мм',
  },
  {
    id: 3,
    text: 'з гарантією до 7 років',
  },
  {
    id: 4,
    text: 'з аналізом на воду',
  },
  {
    id: 5,
    text: 'у бурової компанії з досвідом понад 10 років',
  },
];

export const MainBannerContent = () => {
  return (
    <div className="main-banner__text">
      <Typography variant="h3" gutterBottom>
        Буріння свердловин на воду «під ключ» від <span>550</span> грн./м.
      </Typography>
      <div className="main-banner__subtitle">
        <p>
          Замовте свердловину «під ключ» в <span>«Aquamen»</span>
        </p>
        <List className="main-banner__list">
          {list.map((item) => (
            <ListItem disablePadding key={item.id}>
              <ListItemButton>
                <ListItemIcon style={{ color: '#fece00' }}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};
