import React from 'react';
import { Typography } from '@mui/material';

import './style.scss';

export const InnerContentOfModal = ({ info }) => {
  return (
    <div className="inner-modal">
      <div className="inner-modal-img">
        <img src={info.img} alt={info.title} />

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h6"
          className="centerTitle inner-modal-title"
        >
          {info.title}
        </Typography>
        <p>ООО «Aquamen» гарантує конфіденційність Ваших даних.</p>
      </div>
    </div>
  );
};
