import React from 'react';
import { AppBar } from '@mui/material';

import { NavigationBottom } from './NavigationBottom';
import { NavigationTop } from './NavigationTop';

import './styles.scss';

export const Header = () => {
  return (
    <AppBar position="static">
      <NavigationTop />
      <NavigationBottom />
    </AppBar>
  );
};
