import React from 'react';
import { Container } from '@mui/material';
import { SocialLinks } from '../Header/components/SocialLinks/SocialLinks';
import { FormComponent } from '../MainBanner/components/FormComponent';

import './style.scss';

export const Footer = () => {
  return (
    <div className="footer">
      <Container maxWidth="lg">
        <div className="footer-row">
          <div className="footer-col">
            <p>
              <strong>Aquamen</strong>- буріння свердловин на воду, водопостачання будинку.
            </p>
            <address>Київська область, Києво-Святошинський район, с. Білогородка, вул. Шевченка 50</address>
            <p>Ми працюємо кожен день з 8:00 до 22:00</p>
          </div>
          <div className="footer-col center">
            <p>
              <a href="tel:+380668480433">066-848-04-33</a>
            </p>
            <p>
              <a href="mailto:aquamenthebest@gmail.com">aquamenthebest@gmail.com</a>
            </p>
            <div className="footer-social">
              <SocialLinks />
            </div>
          </div>
          <div className="footer-col">
            <div className="footer-form">
              <FormComponent />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
