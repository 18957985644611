import React, { useState } from 'react';
import { Typography } from '@mui/material';

import Lightbox from 'react-image-lightbox';

export const ListCards = ({ title, imagesList, countColumns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const openImage = (e, imgIndex) => {
    e.preventDefault();
    setImgIndex(imgIndex);
    setIsOpen(true);
  };

  const prevIndex = (imgIndex + imagesList.length - 1) % imagesList.length;
  const nextIndex = (imgIndex + 1) % imagesList.length;

  return (
    <div className="ourWorks">
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        className="textCenter ourWorks-title"
      >
        {title}
      </Typography>

      <div className="ourWorks-list">
        {imagesList.map((imageUrl, i) => (
          <div
            key={i}
            className={
              countColumns === 4
                ? 'ourWorks-item'
                : 'ourWorks-item ourWorks-item__three'
            }
          >
            <a
              href={imageUrl}
              onClick={(e) => openImage(e, i)}
              className="img-thumbnail img-thumbnail-shadow"
            >
              <img src={imageUrl} alt="pictures" />
            </a>
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={imagesList[imgIndex]}
          nextSrc={imagesList[nextIndex]}
          prevSrc={imagesList[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            setImgIndex(prevIndex);
          }}
          onMoveNextRequest={() => {
            setImgIndex(nextIndex);
          }}
        />
      )}
    </div>
  );
};
