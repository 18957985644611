import React, { useEffect, useState } from 'react';
import { Container, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { CONFIRM_STORAGE_ITEM } from '../../constants';
import './style.scss';

export const ConfirmBlock = () => {
  const [showBlock, isShowBlock] = useState(true);
  const getItem = localStorage.getItem(CONFIRM_STORAGE_ITEM);

  useEffect(() => {
    if (getItem) {
      isShowBlock(false);
    }
  }, [getItem]);

  const handleClose = () => {
    localStorage.setItem(CONFIRM_STORAGE_ITEM, true);
    isShowBlock(false);
  };

  return (
    <>
      {showBlock ? (
        <div className="confirm">
          <Container className="confirm__block" maxWidth="lg">
            <div className="confirm__content">
              <p>
                Своєю присутністю на сайті Ви даєте згоду на обробку
                персональних даних.
              </p>
              <IconButton style={{ color: '#fff' }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
};
