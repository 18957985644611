import { ConfirmBlock } from './components/ConfirmBlock/ConfirmBlock';
import { Cost } from './components/Cost/Cost';
import { Header } from './components/Header/Header';
import { MainBanner } from './components/MainBanner/MainBanner';
import { Maintain } from './components/Maintain/Maintain';
import { Map } from './components/Map/Map';
import { OurWorks } from './components/OurWorks/OurWorks';
import { Price } from './components/Price/Price';
import { Services } from './components/Services/Services';
import { Stepper } from './components/Stepper/Stepper';
import { Constructions } from './components/Constructions/Constructions';
import { Variants } from './components/Variants/Variants';
import { Variants2 } from './components/Variants2/Variants2';
import { CarouselComponent } from './components/Carousel/Carousel';
import { ConcreteRings } from './components/ConcreteRings/ConcreteRings';
import { Footer } from './components/Footer/Footer';
import { ActionButtonComponent } from './components/ActionButton/ActionButton';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Header />
      <MainBanner />
      <Services />
      <Maintain />
      <Stepper />
      <Variants />
      <Map />
      <Variants2 />
      <Price />
      <Cost />
      <Constructions />
      <CarouselComponent />
      <OurWorks />
      <ConcreteRings />
      <Footer />
      <ActionButtonComponent />
      <ConfirmBlock />
      <ToastContainer />
    </>
  );
}

export default App;
