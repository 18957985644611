import React from 'react';
import { AddIcCallOutlined } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import { useModal } from '../../hooks/useModal';
import { ModalComponent } from '../Modal/Modal';

import './style.scss';

export const ActionButtonComponent = () => {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Fab
          color="secondary"
          aria-label="add"
          onClick={toggle}
          className="action-button"
        >
          <AddIcCallOutlined />
        </Fab>
      </Box>
      <ModalComponent isShowing={isShowing} onToggle={toggle} />
    </>
  );
};
