import { Container } from '@mui/material';
import React from 'react';
import { Title } from './components/Title';

import img1 from '../../images/pic_14.png';
import img2 from '../../images/pic_15.png';
import img3 from '../../images/pic_16.png';
import img4 from '../../images/pic_17.png';

import './style.scss';

export const Stepper = () => {
  return (
    <div className="stepper ">
      <Title />
      <Container maxWidth="lg">
        <div className="steps-row">
          <div className="steps-item">
            <div className="steps-item__img">
              <img src={img1} alt="Заявка" />
            </div>
            <div className="steps-item__content">
              <div className="steps-item__title">
                <h3>
                  <span>1</span>
                  Заявка
                </h3>
              </div>
              <p>
                Ви телефонуєте нам за номером
                <a href="tel:0668480433">066-848-04-33</a>,або ж лишаєте заявку
                на сайті
              </p>
            </div>
          </div>
          <div className="steps-item">
            <div className="steps-item__img">
              <img src={img2} alt="Розрахунок вартості" />
            </div>
            <div className="steps-item__content">
              <div className="steps-item__title">
                <h3>
                  <span>2</span>
                  Розрахунок вартості
                </h3>
              </div>
              <p>
                Наш спеціаліст виїжджає на місце буріння, рекомендує водоносний
                горизонт, складає детальний кошторис і відправляє Вам на
                погодження. Після погодження підписуємо договір та починаємо
                працювати
              </p>
            </div>
          </div>
          <div className="steps-item">
            <div className="steps-item__img">
              <img src={img3} alt="Буріння" />
            </div>
            <div className="steps-item__content">
              <div className="steps-item__title">
                <h3>
                  <span>3</span>
                  Буріння
                </h3>
              </div>
              <p>В роботу входить:</p>
              <ul>
                <li>• буріння</li>
                <li>• монтаж фільтра та колони</li>
                <li>• обсипка кварцовим піском</li>
                <li>• прокачування до візуально чистої води</li>
              </ul>
            </div>
          </div>
          <div className="steps-item">
            <div className="steps-item__img">
              <img src={img4} alt="Оплата і гарантії" />
            </div>
            <div className="steps-item__content">
              <div className="steps-item__title">
                <h3>
                  <span>3</span>
                  Оплата і гарантії
                </h3>
              </div>
              <p>Після буріння ми:</p>
              <ul>
                <li>• виміряємо при Вас глибину</li>
                <li>• підписуємо Акт прийому-передачі</li>
                <li>• проводимо розрахунок</li>
                <li>• видаємо паспорт та гарантію на свердловину</li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
