import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const navigationTopLinks = [
  { name: 'Буріння свердловин', href: '#mainBanner' },
  { name: 'Облаштування свердловини', href: '#variants' },
  { name: 'Наші роботи', href: '#ourWorks' },
  { name: 'Карта виконаних свердловин', href: '#maps' },
  { name: 'Септики', href: '#variants2' },
  { name: 'Бетонні кільця', href: '#concreteRings' },
];

export const MainNavigation = ({ handleCloseMenu }) => {
  return (
    <>
      {navigationTopLinks.map((item) => (
        <AnchorLink
          color="textPrimary"
          href={item.href}
          key={item.name}
          className="navbar-top__link"
          onClick={handleCloseMenu}
        >
          {item.name}
        </AnchorLink>
      ))}
    </>
  );
};
