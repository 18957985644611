import { Container, Typography } from '@mui/material';
import React from 'react';

export const Title = () => {
  return (
    <div className="stepper-title">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className="semiBold textCenter mainTitle"
        >
          4 простих кроки до Вашої свердловини
        </Typography>
      </Container>
    </div>
  );
};
