import React from 'react';
import { Container, Hidden, Toolbar } from '@mui/material';
import { MainNavigation } from './components/MainNavigation/MainNavigation';

export const NavigationTop = (props) => {
  return (
    <Hidden mdDown>
      <div className="navbar-top">
        <Container maxWidth="lg" disableGutters>
          <Toolbar disableGutters className="navbar-top__toolbar">
            <MainNavigation />
          </Toolbar>
        </Container>
      </div>
    </Hidden>
  );
};
