import React from 'react';
import { Typography } from '@mui/material';

import './style.scss';

export const ListComponent = ({ list, flexStart }) => {
  return (
    <div className="list">
      {list.map((item) => (
        <div className="list-block" key={item.id}>
          <div className="list-img">
            <img src={item.img} alt={item.title} />
          </div>
          <div className={flexStart ? 'list-main flexStart' : 'list-main'}>
            <div className="list-title">{item.title}</div>
            {item.price && (
              <div className="list-price">
                вiд <span className="green semiBold">{item.price}</span> грн/м
              </div>
            )}
            {item.content && (
              <Typography
                variant="body1"
                gutterBottom
                component="div"
                className="list-content"
              >
                {item.content}
              </Typography>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
