import React from 'react';

import { Button } from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import { useModal } from '../../hooks/useModal';
import { ModalComponent } from '../Modal/Modal';

export const CallBackPhone = () => {
  const { isShowing, toggle } = useModal();

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<PhoneIcon />}
        color="error"
        onClick={() => {
          toggle();
        }}
        className="callback-btn"
      >
        Зворотний дзвінок
      </Button>
      <ModalComponent isShowing={isShowing} onToggle={toggle} />
    </>
  );
};
