import React from 'react';
import { Container } from '@mui/material';
import { MainBannerTop } from './components/MainBannerTop';
import { MainBannerContent } from './components/MainBannerContent';
import { MainBannerForm } from './components/MainBannerForm';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './style.scss';
import { BannerActionContent } from './components/BannerActionContent';
import { ActionImg } from './components/ActionImg';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const MainBanner = () => {
  return (
    <Carousel
      responsive={responsive}
      showDots={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      itemClass="carousel-item-test"
      removeArrowOnDeviceType={['tablet', 'mobile']}
      swipeable={true}
      dotListClass="custom-dot-list-style"
    >
      <div className="main-banner" id="mainBanner">
        <div className="blur"></div>
        <Container maxWidth="lg">
          <div className="main-banner__block">
            <div className="main-banner__content">
              <MainBannerTop />
              <MainBannerContent />
            </div>
            <MainBannerForm />
          </div>
        </Container>
      </div>
      <div className="main-banner" id="mainBanner">
        <div className="blur"></div>
        <Container maxWidth="lg" className="container_action">
          <div className="main-banner__block">
            <div className="main-banner__content main-banner__content-action">
              <MainBannerTop />
              <BannerActionContent />
            </div>
            <ActionImg />
          </div>
        </Container>
      </div>
    </Carousel>
  );
};
