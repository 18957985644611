import React from 'react';

import img from '../../../images/actions/img_01.jpg';

export const ActionImg = () => {
  return (
    <div className="main-banner__form main-banner__action-img">
      <div className="main-banner__img-inner">
        <img src={img} alt="комплекс автоматики" />
      </div>
    </div>
  );
};
