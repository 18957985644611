import React, { useState } from 'react';
import { Container, Typography } from '@mui/material';

import img1 from '../../images/img_04.jpg';
import img2 from '../../images/img_05.jpg';
import img3 from '../../images/img_06.jpg';

import './style.scss';
import { VariantItem } from './VariantItem';
import { useModal } from '../../hooks/useModal';
import { ModalComponent } from '../Modal/Modal';
import { InnerContentOfModal } from '../InnerContentOfModal/InnerContentOfModal';

const data = [
  {
    id: 1,
    img: img1,
    title: 'На основі оголовка',
    text: 'Зручно використовувати на період будівництва, чи в період весна-осінь для дачі, тимчасовий варіант',
    price: '15 000',
  },
  {
    id: 2,
    img: img2,
    title: 'На основі бетонніх кілець',
    text: 'Найоптимальніший варіант, має якісні експлуатаційні та захисні характеристики.',
    price: '30 000',
  },
  {
    id: 3,
    img: img3,
    title: 'На основі кесона',
    text: 'Кесон являє собою пластикову, герметичну емкість, яка захищає від грунтових вод, є якісним, але дорогим рішенням.',
    price: '40 000',
  },
];

export const Variants = () => {
  const { isShowing, toggle } = useModal();
  const [isClicked, setIsClicked] = useState(null);

  const handleSetClick = (value) => {
    toggle();
    setIsClicked(data.find(({ id }) => value === id));
  };

  return (
    <div className="block-padding variants" id="variants">
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom component="div" className=" semiBold textCenter mainTitle">
          Варіанти облаштування свердловини «під ключ»
        </Typography>
        <div className="variants-row">
          {data.map((item, i) => (
            <VariantItem
              key={item.id}
              title={item.title}
              img={item.img}
              text={item.text}
              price={item.price}
              active={i === 1}
              onClick={handleSetClick}
              id={item.id}
            />
          ))}
        </div>

        <ModalComponent isShowing={isShowing} onToggle={toggle}>
          {isClicked && <InnerContentOfModal info={isClicked} />}
        </ModalComponent>
      </Container>
    </div>
  );
};
