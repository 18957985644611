import React from 'react';
import { Container, Typography } from '@mui/material';

import img1 from '../../images/list/1.jpg';
import img2 from '../../images/list/2.jpg';
import img3 from '../../images/list/3.jpg';
import img4 from '../../images/list/4.jpg';
import { ListComponent } from '../ListComponent/ListComponent';

const list = [
  {
    id: 1,
    title: 'Буріння свердловин',
    price: '550',
    img: img1,
  },
  {
    id: 2,
    title: 'Облаштування свердловин',
    price: '15 000',
    img: img2,
  },
  {
    id: 3,
    title: 'Буріння під теплові насоси',
    price: '150',
    img: img3,
  },
  {
    id: 4,
    title: 'Септики',
    price: '15 000',
    img: img4,
  },
];

export const Services = () => {
  return (
    <div className="block-padding">
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom component="div" className="upperCase semiBold textCenter mainTitle">
          Отримуйте лише якісні послуги за {''}
          <span className="green">доступними цінами</span>
        </Typography>

        <Typography variant="h6" gutterBottom component="div" className="textCenter">
          Наші основні послуги:
        </Typography>

        <ListComponent list={list} />
      </Container>
    </div>
  );
};
