import React from 'react';

export const PriceItem = ({ active, title, src, price, onClick, id }) => {
  return (
    <>
      {active ? (
        <div className="price-item price-decor">
          <div className="price-decor__inner">
            <div className="price-header">
              <span>{title}</span>
            </div>
            <div className="price-main">
              <div className="new_img_transform">
                <div className="top_y_parallelogram"></div>
                <p className="center_w_parallelogram">
                  <img src={src} alt="img" />
                </p>
                <div className="bottom_y_parallelogram"></div>
              </div>
            </div>
            <div className="price-footer">
              <span>
                від <strong>{price}</strong> грн/м
              </span>
              <a
                href="#popup:50.1mm"
                onClick={() => onClick(id)}
                className="price-open btn"
              >
                Замовити
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className={'price-item'}>
          <div className={'price-default'}>
            <div className="price-header">
              <span>{title}</span>
            </div>
            <div className="price-main">
              <img src={src} alt="img" />
            </div>
            <div className="price-footer">
              <span>
                від <strong>{price}</strong> грн/м
              </span>
              <a
                href="#popup:50mm"
                onClick={() => onClick(id)}
                className="price-open btn"
              >
                Замовити
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
