import { Container, Typography } from '@mui/material';
import React from 'react';

import img1 from '../../images/pic_18.png';
import img2 from '../../images/pic_19.png';
import img3 from '../../images/pic_20.png';
import img4 from '../../images/pic_21.png';

import './style.scss';

export const Cost = () => {
  return (
    <div className="block-padding cost">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className=" semiBold textCenter mainTitle"
        >
          В загальну вартість входить
        </Typography>

        <div className="cost-row">
          <div className="cost-item">
            <div className="cost-block">
              <img src={img1} alt="img" />
              <p>Доставка техніки на місце буріння+буріння</p>
            </div>
          </div>
          <div className="cost-item">
            <div className="cost-block">
              <img src={img2} alt="img" />
              <p>Обсадні труби+кварцова обсипка</p>
            </div>
          </div>
          <div className="cost-item">
            <div className="cost-block">
              <img src={img3} alt="img" />
              <p>Прокачка до візуально чистої води</p>
            </div>
          </div>
          <div className="cost-item">
            <div className="cost-block">
              <img src={img4} alt="img" />
              <p>Аналіз води та гарантії</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
