import React, { useState, useCallback } from 'react';
import {
  Container,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import logo from '../../images/logo.png';

import { MobileMenu } from './MobileMenu';
import { SocialLinks } from './components/SocialLinks/SocialLinks';
import { Phones } from './components/Phones/Phones';
import { CallBackPhone } from '../CallBackPhone/CallBackPhone';

export const NavigationBottom = () => {
  const [open, setOpen] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setOpen(false);
  }, []);
  const handleOpenMenu = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div className="navbar-bottom">
      <Container maxWidth="lg" disableGutters>
        <Toolbar disableGutters className="navbar-bottom__toolbar">
          <div className="navbar-bottom__left">
            <div className="navbar-bottom__logotype">
              <img src={logo} alt="aquamen" />
              <h1>Aquamen</h1>
            </div>
            <Hidden mdDown>
              <div className="navbar-bottom__description">
                <Typography variant="body2">
                  Буріння і облаштування свердловини
                </Typography>
                <Typography variant="body2">
                  Чіткість Якість Відповідальність
                </Typography>
              </div>
            </Hidden>
          </div>
          <Hidden mdDown>
            <div className="navbar-bottom__right">
              <Phones />
              <SocialLinks />
              <CallBackPhone />
            </div>
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={handleOpenMenu}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>

      <MobileMenu
        open={open}
        handleCloseMenu={handleCloseMenu}
        handleOpenMenu={handleOpenMenu}
      />
    </div>
  );
};

//{
/* {navigationTopLinks.map((item) => (
              <Link
                color="textPrimary"
                variant="button"
                underline="hover"
                href={item.href}
                key={item.name}
              >
                {item.name}
              </Link>
            ))} */
//}
