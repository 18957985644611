import React from 'react';
import { Container, Typography } from '@mui/material';

import './style.scss';

import img1 from '../../images/list/5.jpg';
import img2 from '../../images/list/6.jpg';
import img3 from '../../images/list/7.jpg';
import img4 from '../../images/list/1.jpg';
import img5 from '../../images/list/price.jpg';
import img6 from '../../images/list/time.jpg';
import img7 from '../../images/list/product.jpg';
import img8 from '../../images/list/contract.jpg';

import { ListComponent } from '../ListComponent/ListComponent';

const list = [
  {
    id: 1,
    title: 'Понад 10 років досвіду',
    content:
      'За цей час нами пробурено понад 50 кілометрів. Ми досконало знаємо геологічні особливості кожного населеного пункту Київської області.',
    img: img1,
  },
  {
    id: 2,
    title: 'Свердловина «під ключ»',
    content:
      'Ми працюємо по принципу «все враховано». Ви звертаєтесь до нас, а ми пробуримо та облаштуємо свердловину, при потребі підберемо систему фільтрації, реалізуємо водовідведення.',
    img: img2,
  },
  {
    id: 3,
    title: 'Власний парк техніки',
    content:
      'В нас свій парк техніки з велико- та малогабаритних бурових установок',
    img: img3,
  },
  {
    id: 4,
    title: 'Без передоплати',
    content:
      'Ми впевнені в якості наших робіт та кваліфікації працівників, тому ми не хвилюємось за результат і готові працювати без передоплати. Ви платите по факту виконаних робіт.',
    img: img4,
  },
  {
    id: 5,
    title: 'Без зміни ціни',
    content:
      'Ми чітко дотримуємося кошторису, затвердженого до початку робіт. Гарантуємо!',
    img: img5,
  },
  {
    id: 6,
    title: 'Виконуємо все вчасно',
    content:
      'Ми цінуємо Ваш час, тому максимально дотримуємось поставлених часових рамок.',
    img: img6,
  },
  {
    id: 7,
    title: 'Кращі обсадні труби',
    content:
      'Нами використовуються лише сертифіковані труби НПВХ (Броварський завод пластмас та VALROM)',
    img: img7,
  },
  {
    id: 8,
    title: 'Гарантія до 7-ми років Договір Паспорт',
    content:
      'Працюємо по договору. По закінченню робіт Ви отримуєте паспорт з гарантією та рекомендаціями.',
    img: img8,
  },
];

export const Maintain = () => {
  return (
    <div className="block-padding">
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className="upperCase semiBold textCenter mainTitle withImage"
        >
          Чому <span className="red">90 людей зі 100</span>, що до нас
          звернулися, стають нашими клієнтами
        </Typography>

        <ListComponent list={list} flexStart />
      </Container>
    </div>
  );
};
