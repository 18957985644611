import React from 'react';
import { Typography } from '@mui/material';
import { FormComponent } from './FormComponent';

export const MainBannerForm = () => {
  return (
    <div className="main-banner__form">
      <div className="main-banner__form-inner">
        <Typography variant="h6" className="main-banner__form-title">
          Укажіть свій телефон і ми зв'яжимось з вами
        </Typography>
        <FormComponent />
      </div>
    </div>
  );
};
